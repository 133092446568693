
import Vue from 'vue'
import { ICategory } from 'types/DTO/categories'
import breakpoints from '~/mixins/breakpoints'
export default Vue.extend({
  name: 'RPHCatalogList',
  components: {},
  mixins: [breakpoints],
  props: {
    isPharmacy: {
      type: Boolean,
      default: false,
    },
    catalogIsOpen: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      children: [] as ICategory[],
      isSubmenuOpen: false,
      isTablet: false,
    }
  },

  mounted() {
    this.isTablet = window.innerWidth <= 1050
  },
  computed: {
    categories(): ICategory[] {
      return this.$store.getters['rph-categories/categories']
    },
  },
  methods: {
    setChildren(child: ICategory[]) {
      this.isSubmenuOpen = true
      this.children = child
    },
    selectCategory() {
      this.isSubmenuOpen = false
      this.$emit('close-catalog')
    },
  },
})
