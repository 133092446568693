
import Vue from 'vue'
import WhatsApp from 'icons/whatsapp.svg?inline'
export default Vue.extend({
  name: 'RPHHeaderWhatsApp',
  components: {
    WhatsApp,
  },
  props: {
    phone: {
      type: String,
      default: '+77777474885',
    },
  },
  computed: {
    whatsappPhone() {
      return `https://wa.me/${this.phone}`
    },
  },
  methods: {},
})
