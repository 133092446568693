
import Vue from 'vue'
import { IGeocoderAddress } from 'types/DTO/geocoder'

export default Vue.extend({
  name: 'RPHFindAddress',
  components: {
    Heading: () => import('~/components/atoms/Heading.vue'),
    RPHButton: () => import('rph/atoms/RPHButton/index.vue'),
    RPHInputSearchPosition: () => import('rph/atoms/RPHInputSearchPosition/index.vue'),
  },
  data() {
    return {
      value: '',
      coords: [] as number[],
      showMap: false,
      markerIcon: {
        layout: 'default#image',
        imageHref: require('~/assets/icons/marker-position.svg'),
        imageSize: [32, 25],
      },
      flat: '',
      flatError: false,
    }
  },
  computed: {
    address(): IGeocoderAddress {
      return this.$store.getters['rph-geocoder/address']
    },
    foundAddress(): string {
      return Object.keys(this.address).length
        ? this.$services.geocoder.formatAddress(this.address)
        : ''
    },
    isButtonDisabled(): boolean {
      return !this.flat.length || this.flatError || !this.foundAddress.length
    },
  },
  methods: {
    setCoords(values: number[]): void {
      this.coords = values
      this.$store.dispatch('rph-geocoder/getAddressByCoords', values[1] + ',' + values[0])
    },
    setAddress(): void {
      const addressObj = {
        street: this.address.street,
        house: this.address.house,
        flat: this.flat,
        city: this.address.locality,
        city_id: '',
      }
      this.$store.commit('rph-order/setNewAddress', { ...this.address, flat: this.flat })
      this.$store.commit('rph-order/setOrderAddress', addressObj)
      this.$store.dispatch('rph-modals/clearModals')
    },
  },
})
