
import Vue, { PropType } from 'vue'
import RPHButton from 'rph/atoms/RPHButton/index.vue'
import { IProfileAddress } from 'types/DTO/profile'
import LocationIcon from '~/assets/icons/address-location.svg?inline'

export default Vue.extend({
  name: 'RPHHeaderLocationButton',
  components: {
    LocationIcon,
    RPHButton,
  },
  props: {
    address: {
      type: Object as PropType<IProfileAddress>,
    },
  },
  data() {
    return {}
  },
  computed: {},
  methods: {},
})
