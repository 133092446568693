
import Vue from 'vue'
import Close from 'icons/close.svg?inline'
import RPHHeaderNav from 'rph/molecules/RPHHeaderNav/index.vue'
import RPHFooterSocials from 'rph/molecules/RPHFooterSocials/index.vue'
import RPHPayments from 'rph/molecules/RPHPayments/index.vue'

export default Vue.extend({
  name: 'RPHBurgerMenu',
  components: {
    Close,
    RPHHeaderNav,
    RPHFooterSocials,
    RPHPayments,
  },
  props: {
    open: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    companyEmail(): string {
      return (
        this.settings.find((setting) => setting.attributes.param === 'order_email_support')
          ?.attributes?.value || ''
      )
    },
    companyPhone(): string {
      return (
        this.settings.find((setting) => setting.attributes.param === 'contact-phone-number')
          ?.attributes?.value || ''
      )
    },
    companyDetails(): string {
      return (
        this.settings
          .find((setting) => setting.attributes.param === 'company_details')
          ?.attributes?.value.replace(/\r\n|\n/g, '<br>') || ''
      )
    },
    workHours(): string {
      return this.$store.getters['rph-footer/workHours'] || ''
    },
    settings(): Array<any> {
      return this.$store.getters['rph-footer/settings'] || []
    },
  },
  methods: {
    closeHandler(e: MouseEvent) {
      this.$emit('click', e)
    },
  },
})
