
import Vue from 'vue'
import { mapMutations } from 'vuex'

import Heading from '~/components/atoms/Heading.vue'
import RPHButton from 'rph/atoms/RPHButton/index.vue'

export default Vue.extend({
  name: 'RPHLogoutConfirm',
  components: { RPHButton, Heading },
  computed: {
    basketId() {
      return this.$store.getters['rph-basket/basketId']
    },
  },
  methods: {
    ...mapMutations({
      SET_SIGNIN_ACTIVE: 'signin/SET_SIGNIN_ACTIVE',
      CLEAR_PROFILE: 'profile/CLEAR_PROFILE',
      SET_PRODUCTS: 'rph-basket/setProducts',
    }),
    closeHandler(): void {
      this.$store.dispatch('rph-modals/clearModals')
    },
    confirmHandler(): void {
      this.$router.push('/')
      // @ts-ignore
      this.$auth.logout()
      // @ts-ignore
      this.$auth.setUser(null)
      this.$axios.setToken('cUN5-MNgeC6NY-nG_edUpAijsPfzT32H', 'Bearer')
      this.SET_SIGNIN_ACTIVE(false)
      this.$services.localStorageBasket.clearBasket()
      this.SET_PRODUCTS([])
      this.CLEAR_PROFILE()
      this.$store.dispatch('rph-modals/clearModals')
      this.$services.toaster.add({
        type: 'success',
        title: '',
        description: 'Выход выполнен успешно',
      })
    },
  },
})
