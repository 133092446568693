
import Vue from 'vue'
import RPHHeaderLocationButton from 'rph/molecules/RPHHeaderLocationButton/index.vue'
import { IProfileAddress, IProfileAddressNew } from 'types/DTO/profile'

export default Vue.extend({
  name: 'RPHHeaderLocation',
  components: {
    RPHHeaderLocationButton,
  },
  props: {},
  data() {
    return {}
  },
  computed: {
    addresses(): IProfileAddress[] {
      return this.$store.getters['rph-profile/addresses'].map((address: IProfileAddressNew) => ({
        id: address?.id,
        title: `${address?.city.title}, ул.${address?.street}, д. ${address?.house} ${
          address.flat ? ', кв. ' + address.flat : ''
        }`,
        street: address.street,
        house: address.house,
        flat: address.flat,
        city_id: address.city.id,
        is_default: !!address.is_default,
      }))
    },
    defaultAddress(): IProfileAddress | null {
      const defaultAddress = this.addresses.find((address) => !!address.is_default)

      if (defaultAddress) {
        return defaultAddress
      }

      return null
    },
  },
  methods: {},
})
