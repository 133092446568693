
import Vue from 'vue'
import RPHFooterSocialLink from 'rph/atoms/RPHFooterSocialLink/index.vue'
import { ISocial } from 'types/DTO/socials'

export default Vue.extend({
  name: 'RPHFooterSocials',
  components: {
    RPHFooterSocialLink,
  },
  props: {},
  computed: {
    socials(): ISocial {
      return this.$store.getters['rph-footer/socials']
    },
  },
  methods: {},
})
