
import Vue from 'vue'
import breakpoints from '@/mixins/breakpoints'
import { IMenuObject } from '~/types/DTO/menu'

export default Vue.extend({
  name: 'RPHFooterNav',
  mixins: [breakpoints],
  data() {
    return {}
  },
  computed: {
    menu(): IMenuObject[] {
      return this.$store.getters['rph-menu/footer'] || []
    },
    lastElemOfMenu(): any {
      if (!this.menu.length) return {}
      return this.menu[this.menu.length - 1]
    },
  },
  methods: {},
})
