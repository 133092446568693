
import Vue, { PropType } from 'vue'
import { IBanner } from 'types/DTO/banners'

type LinkAttribute = 'href' | 'to'

interface ILink {
  href?: string
  to?: string
}

export default Vue.extend({
  name: 'RPHSmallBanner',
  props: {
    bannerData: {
      type: Object as PropType<IBanner>,
      required: true,
    },
    isPreload: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    baseUrl(): string {
      return process.env.BASE_URL || ''
    },
    is(): 'NuxtLink' | 'a' {
      return this.bannerData.link.external ? 'a' : 'NuxtLink'
    },
    link(): ILink {
      const linkAttribute: LinkAttribute = this.bannerData.link.external ? 'href' : 'to'
      return {
        [linkAttribute]: this.bannerData.link.link,
      }
    },
  },
})
