export const EventsViewedCategory = {
  Popular: { id: 1, name: 'Популярные товары' },
  Inexpensive: { id: 2, name: 'Недорогие лекарства' },
  Categories: { id: 3, name: 'Категории каталога' },
  Analogues: { id: 4, name: 'Блок аналогов' },
  Pharmacies: { id: 5, name: 'Страница аптеки' },
  News: { id: 6, name: 'Страница новости' },
  Inns: { id: 7, name: 'МНН' },
  Producers: { id: 8, name: 'Производитель' },
  NotFound: { id: 9, name: '404 страница' },
  Basket: { id: 10, name: 'Корзина' },
  Search: { id: 11, name: 'Страница поиска' },
  Disease: { id: 12, name: 'Болезни' },
  Product: { id: 13, name: 'Страница продукта' },
}
