
import Vue from 'vue'
import RPHPaymentItem from 'rph/atoms/RPHPaymentItem/index.vue'

export default Vue.extend({
  name: 'RPHPayments',
  components: {
    RPHPaymentItem,
  },
  props: {},
  data() {
    return {}
  },
  computed: {},
  methods: {},
})
