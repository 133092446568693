
import Vue from 'vue'

export default Vue.extend({
  name: 'RPHModalWindow',
  props: {
    type: {
      type: String,
      default: '',
    },
    notCloseBg: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    toggleModal() {
      if (this.notCloseBg) {
        return null
      } else this.$store.commit('rph-modals/clearModals')
    },
  },
})
