
import Vue from 'vue'
import Basket from 'icons/basket-cart--desktop.svg?inline'
import RPHButton from 'rph/atoms/RPHButton/index.vue'
import RPHBadge from 'rph/atoms/RPHBadge/index.vue'

export default Vue.extend({
  name: 'RPHHeaderBasket',
  components: {
    Basket,
    RPHBadge,
    RPHButton,
  },

  methods: {
    async goToCart() {
      if (this.$route.path !== '/cart') {
        // @ts-ignore: Unreachable code error
        await this.$gtm.push({ event: 'click_on_cart' })
      }
      await this.$router.push('/cart')
    },
  },

  computed: {
    products(): any {
      return this.$store.getters['rph-basket/productsPrice']
    },
    count(): number {
      return this.$store.getters['rph-basket/totalCount']
    },
    price(): number {
      return this.$store.getters['rph-basket/totalPrice']
    },

    promoCode(): any {
      return this.$store.getters['rph-order/promoCodeDiscount']
    },
  },
})
