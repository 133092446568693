import { render, staticRenderFns } from "./index.vue?vue&type=template&id=0f9d1356&scoped=true"
import script from "./index.vue?vue&type=script&lang=ts"
export * from "./index.vue?vue&type=script&lang=ts"
import style0 from "./index.vue?vue&type=style&index=0&id=0f9d1356&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f9d1356",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/usr/src/app/src/components/atoms/Button.vue').default,RPHInputSearch: require('/usr/src/app/src/components/v2/atoms/RPHInputSearch/index.vue').default,RPHCatalogList: require('/usr/src/app/src/components/v2/molecules/RPHCatalogList/index.vue').default,RPHSearchResult: require('/usr/src/app/src/components/v2/molecules/RPHSearchResult/index.vue').default})
