
import Vue, { PropType } from 'vue'
import { IProduct } from 'types/DTO/products'
import { EventsViewedCategory } from '~/enums/eventsViewedCategory'
export default Vue.extend({
  name: 'RPHNearestProductsGrid',
  components: {
    RPHProductCard: () => import('rph/molecules/RPHProductCard/index.vue'),
  },
  props: {
    slice: {
      type: Boolean,
      default: false,
    },
    isDeliveryPage: {
      type: Boolean,
      default: false,
    },
    additionalKey: {
      type: String,
      default: '',
    },
    products: {
      type: Array as PropType<IProduct[]>,
    },
  },
  computed: {
    nearestProducts() {
      if (this.isDeliveryPage && this.slice) return this.products.slice(0, 4)
      return this.slice ? this.products.slice(0, 5) : this.products
    },
    itemListData() {
      switch (this.additionalKey) {
        case 'not-found':
          return {
            item_list_id: EventsViewedCategory.NotFound.id,
            item_list_name: EventsViewedCategory.NotFound.name,
          }
        default:
          return false
      }
    },
  },

  mounted() {
    if (this.itemListData) {
      this.$services.productsEvents.productViewPushEvent({
        products: this.products,
        itemListData: this.itemListData,
      })
    }
  },
  methods: {},
})
