
import Vue from 'vue'
import { IBanner } from 'types/DTO/banners'

export default Vue.extend({
  name: 'RPHSmallBannerGrid',
  components: {
    RPHSmallBanner: () => import('rph/molecules/RPHSmallBanner/index.vue'),
  },
  computed: {
    banners(): IBanner {
      return this.$store.getters['rph-banners/smallBanners']
    },
  },
})
