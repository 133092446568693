var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('article',{class:[
    'rph-product-card',
    _vm.type === 'horizontal' && 'rph-product-card-row',
    _vm.type === 'search' && 'rph-product-card-search',
  ]},[(_vm.productData.extra?.is_new)?[_c('RPHTags',{attrs:{"show-new":_vm.productData.extra?.is_new}})]:_vm._e(),_vm._v(" "),_c('div',{class:['rph-product-card__image', _vm.type === 'horizontal' && 'rph-product-card__image-thumb']},[(_vm.isBlurred)?_c('span',{staticClass:"rph-product-card__restricted-label"},[_vm._v(" +18 ")]):_vm._e(),_vm._v(" "),_c('NuxtLink',{staticClass:"rph-product-card__link",class:{ 'is-blurred': _vm.isBlurred },attrs:{"to":_vm.productSlug,"title":_vm.productData.seo.title}}),_vm._v(" "),(_vm.productImage.src)?_c('nuxt-img',{class:{ 'is-blurred': _vm.isBlurred },attrs:{"src":_vm.productImage.src,"loading":"lazy","alt":_vm.productImage.alt}}):_vm._e()],1),_vm._v(" "),_c('div',{class:['rph-product-card__content', _vm.type === 'horizontal' && 'rph-product-card-row-mobile']},[_c('div',{staticClass:"rph-product-card-row-table-none"},[(_vm.type === 'horizontal')?[_c('RPHTableProduct',{attrs:{"product-spec":_vm.productTableInfo.specification,"product-producer":_vm.productTableInfo.producers,"product":_vm.product}}),_vm._v(" "),(_vm.isHotPrice)?_c('p',{staticClass:"rph-product-card__expiration"},[_vm._v("\n          У этой версии товара срок годности истечет 17.06.2022. Вы можете приобрести этот товар с\n          27% скидкой\n        ")]):_vm._e()]:_vm._e()],2),_vm._v(" "),_c('div',{class:[_vm.type === 'horizontal' && 'rph-product-card__price-block']},[_c('div',{staticClass:"rph-product-card__price"},[(_vm.availablePrice && _vm.productPrice.price)?_c('p',{staticClass:"rph-product-card__price-text",class:{
            'rph-product-card__price-text_red': _vm.productPrice.discount && _vm.productOldPrice,
          }},[_vm._v("\n          "+_vm._s(_vm.productPrice.price.toLocaleString('ru-RU'))+" ₸\n          "),(_vm.productPrice.discount && _vm.productOldPrice)?_c('s',[_vm._v("\n            "+_vm._s(_vm.productOldPrice)+"\n            ₸\n          ")]):_vm._e(),_vm._v(" "),(_vm.productPrice.discount)?_c('span',[_c('i',[_vm._v(" -"+_vm._s(Math.trunc(_vm.productPrice.discount))+"%")])]):_vm._e()]):_c('p',{staticClass:"rph-product-card__price-text rph-product-card__price-text--small"},[_vm._v("\n          Нет в наличии\n        ")])]),_vm._v(" "),_c('p',{staticClass:"rph-product-card__title",attrs:{"title":_vm.productData.title}},[_c('NuxtLink',{staticClass:"rph-product-card__link",attrs:{"to":_vm.productSlug,"title":_vm.product.seo.title}}),_vm._v(" "),_c('NuxtLink',{staticClass:"rph-product-card__link",attrs:{"to":_vm.productSlug,"title":_vm.product.seo.title}}),_vm._v("\n        "+_vm._s(_vm.product.title)+"\n      ")],1)]),_vm._v(" "),(_vm.type === 'horizontal')?_c('div',{staticClass:"rph-product-card__controls"},[(_vm.inBasket)?_c('ProductCounter',{attrs:{"is-catalog-card":true,"with-counter":true,"transparent":"","text":_vm.counterText,"type":"horizontal","value":_vm.productAmount,"limit":_vm.productPrice.count,"available":_vm.productPrice.count},on:{"update:value":[function($event){_vm.productAmount=$event},function($event){_vm.setProductAmount(
            _vm.basketId,
            Number(_vm.productData.id),
            $event,
            _vm.pharmacy.pharmacyId,
            _vm.pharmacy.providerId,
          )}],"input":function($event){_vm.setProductAmount(
            _vm.basketId,
            Number(_vm.productData.id),
            $event,
            _vm.pharmacy.pharmacyId,
            _vm.pharmacy.providerId,
          )}}}):_vm._e(),_vm._v(" "),(!_vm.inBasket)?_c('RPHButton',{staticClass:"rph-product-card__button",attrs:{"disabled":_vm.availablePrice && !_vm.productPrice.price},on:{"click":function($event){return _vm.basketAction(_vm.inBasket)}}},[(_vm.isAddingLoading)?_c('div',{staticClass:"rph-product-card__button-spinner"}):_vm._e(),_vm._v(" "),(!_vm.isAddingLoading)?_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(require('~/assets/icons/basket-cart--desktop.png')),expression:"require('~/assets/icons/basket-cart--desktop.png')"}],attrs:{"alt":"Иконка корзины"}}):_vm._e(),_vm._v(" "),(!_vm.isAddingLoading)?_c('span',[_vm._v(_vm._s(_vm.inBasket ? 'Товар в корзине' : 'В корзину'))]):_vm._e()]):_vm._e()],1):_vm._e()]),_vm._v(" "),(_vm.type !== 'horizontal')?_c('div',{staticClass:"rph-product-card__controls"},[(_vm.inBasket)?_c('ProductCounter',{attrs:{"is-catalog-card":true,"with-counter":true,"transparent":"","text":_vm.counterText,"type":"full","value":_vm.productAmount,"limit":_vm.productPrice.count,"available":_vm.productPrice.count},on:{"update:value":[function($event){_vm.productAmount=$event},function($event){_vm.setProductAmount(
          _vm.basketId,
          Number(_vm.productData.id),
          $event,
          _vm.pharmacy.pharmacyId,
          _vm.pharmacy.providerId,
        )}],"input":function($event){_vm.setProductAmount(
          _vm.basketId,
          Number(_vm.productData.id),
          $event,
          _vm.pharmacy.pharmacyId,
          _vm.pharmacy.providerId,
        )}}}):_vm._e(),_vm._v(" "),(!_vm.inBasket)?_c('RPHButton',{staticClass:"rph-product-card__button",attrs:{"disabled":_vm.availablePrice && !_vm.productPrice.price},on:{"click":function($event){$event.preventDefault();return _vm.basketAction(_vm.inBasket)}}},[(_vm.isAddingLoading)?_c('div',{staticClass:"rph-product-card__button-spinner"}):_vm._e(),_vm._v(" "),(!_vm.isAddingLoading)?_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(require('~/assets/icons/basket-cart--desktop.png')),expression:"require('~/assets/icons/basket-cart--desktop.png')"}],attrs:{"alt":"Иконка корзины"}}):_vm._e(),_vm._v(" "),(!_vm.isAddingLoading)?_c('span',[_vm._v(_vm._s(_vm.inBasket ? 'В корзине' : 'В корзину'))]):_vm._e()]):_vm._e()],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }