
export default {
  name: 'RPHFooterContacts',
  props: {
    phone: {
      type: String,
      default: '',
    },
    email: {
      type: String,
      default: '',
    },
  },
}
