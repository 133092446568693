
import { IProduct, IProductPrice, IProductTableInfo } from 'types/DTO/products'
import Vue from 'vue'

export default Vue.extend({
  name: 'RPHProductCard',
  components: {
    // Pickup,
    RPHButton: () => import('rph/atoms/RPHButton/index.vue'),
    Heading: () => import('~/components/atoms/Heading.vue'),
    RPHTableProduct: () => import('rph/atoms/RPHTableProduct/index.vue'),
    ProductCounter: () => import('~/components/atoms/ProductCounter.vue'),
    RPHTags: () => import('~/components/v2/atoms/RPHTags/index.vue'),
  },
  props: {
    productData: {
      type: Object,
      required: true,
    },
    /**
     * @values horizontal, vertical
     */
    type: {
      type: String,
      default: 'vertical',
    },
    additionalKey: {
      type: String,
      default: 'category',
    },
    counterText: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isBlurred: false,
      basket: false,
      value: 1,
      expProductModalActive: false,
      isAddingLoading: false,
    }
  },
  computed: {
    product(): IProduct {
      return this.productData
    },

    productSlug(): string {
      return `/product/${this.productData.slug}`
    },

    productImage(): { src: string; alt: string } {
      return {
        src: this.product.images[0].links.medium,
        alt: this.product.images[0].title,
      }
    },

    productTableInfo(): IProductTableInfo {
      return {
        specification: this.product.specification,
        producers: this.product.producers,
      }
    },

    productDescription(): string {
      const { description, short_description } = this.product?.annotation
      return short_description || description || ''
    },

    availablePrice(): string {
      return this.$services.price.getDefaultPrice(this.product.prices as any)
    },

    productPrice(): IProductPrice | any {
      return this.$services.productInBasket.getPrice(this.product)
    },

    productOldPrice(): number {
      return this.$services.price.getOldPrice(this.productPrice)
    },

    productFastDelivery(): number {
      return this.product.prices.fast_delivery.price
    },

    tomorrowDelivery(): boolean {
      return new Date().getHours() < 18
    },

    isHotPrice(): boolean {
      const { hot_price } = this.productData?.prices
      return hot_price && hot_price.price
    },

    pharmacy(): any {
      return this.$services.productInBasket.getPharmacyId(this.productData)
    },

    inBasket(): boolean {
      const idArr = this.$store.getters['rph-basket/basketProductsId']
      return idArr.includes(this.product.id)
    },

    globalBlurState(): boolean {
      return this.$store.getters['rph-categories/isBlurred']
    },

    basketList(): any {
      return this.$store.getters['rph-basket/basketList']
    },
    basketId(): number {
      return this.$store.getters['rph-basket/basketId']
    },
    productAmount: {
      get(): number {
        const product = this.basketList.find((p: any) => p.id === this.product.id)
        return product !== undefined && product.count ? product.count : this.value
      },
    },
  },
  mounted() {
    this.checkAge()
  },
  watch: {
    globalBlurState() {
      this.checkAge()
    },
  },
  methods: {
    async basketAction(type: boolean) {
      const { isHot, pharmacyId, providerId } = this.$services.productInBasket.getPharmacyId(
        this.product,
      )
      const basketId = this.basketId
      const id = this.product.id
      if (type) {
        await this.$services.productInBasket.deleteProductInBasket(
          String(basketId),
          id,
          pharmacyId,
          providerId,
        )
      } else {
        this.isAddingLoading = true
        await this.$services.productInBasket.addProductInBasket(
          String(basketId),
          id,
          1,
          pharmacyId,
          isHot,
          providerId || '',
          this.additionalKey,
        )
        this.isAddingLoading = false
      }
    },
    async setProductAmount(
      id: number,
      product_id: number,
      val: number,
      pharmacy_id: number,
      provider_id: number,
    ) {
      const params = new URLSearchParams()
      params.append('count', String(val))
      if (val === 0) {
        await this.$services.productInBasket.deleteProductInBasket(
          String(id),
          String(product_id),
          pharmacy_id,
          provider_id,
        )
      } else {
        await this.$store.dispatch('rph-basket/changeCount', {
          id,
          product_id,
          pharmacy_id,
          provider_id,
          params,
          val,
        })
      }
    },

    checkAge() {
      if (this.productData.is_adult) {
        this.isBlurred =
          !localStorage.getItem('isAdult') || localStorage.getItem('isAdult') !== 'true'
      }
    },
  },
})
