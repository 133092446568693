
import Vue from 'vue'

import CloseIcon from '~/assets/svg/close-modal.svg?inline'
export default Vue.extend({
  name: 'RPHPharmacyReport',
  components: {
    RPHContactForm: () => import('rph/organisms/RPHContactForm/index.vue'),
    RPHButton: () => import('rph/atoms/RPHButton/index.vue'),
    CloseIcon,
  },
  data() {
    return {}
  },
  computed: {},
  methods: {
    toggleModal(): void {
      this.$store.dispatch('rph-modals/clearModals')
    },
  },
})
