
import Vue from 'vue'

export default Vue.extend({
  name: 'RPHContactForm',
  components: {
    RPHButton: () => import('rph/atoms/RPHButton/index.vue'),
    RPHInput: () => import('rph/atoms/RPHInput/index.vue'),
    TextArea: () => import('~/components/atoms/textarea.vue'),
    Heading: () => import('~/components/atoms/Heading.vue'),
    CheckIcon: () => import('~/assets/icons/checked-green.svg?inline'),
  },
  props: {
    source: {
      type: String,
      default: 'feedback',
    },
  },
  data() {
    return {
      name: '',
      nameError: false,
      phone: '',
      phoneError: false,
      message: '',
      messageError: false,
      email: '',
      emailError: false,
    }
  },
  computed: {
    serverNameError(): string {
      return this.$store.getters['rph-contacts-notify/nameError']
    },
    serverPhoneError(): string {
      return this.$store.getters['rph-contacts-notify/phoneError']
    },
    serverTextError(): string {
      return this.$store.getters['rph-contacts-notify/messageError']
    },
    serverEmailError(): string {
      return this.$store.getters['rph-contacts-notify/emailError']
    },
    success(): boolean {
      return this.$store.getters['rph-contacts-notify/success']
    },
    disabled(): boolean {
      return (
        !(this.name.length > 1) ||
        !(this.phone.length > 1) ||
        !(this.message.length > 1) ||
        this.phoneError ||
        this.nameError ||
        this.messageError
      )
    },
  },
  watch: {
    name(newVal: string) {
      this.$store.commit('rph-contacts-notify/setNameError', '')
      if (newVal.length) this.nameError = !this.$services.common.validator.nameRegex.test(newVal)
    },
    email(newVal: string) {
      this.$store.commit('rph-contacts-notify/setEmailError', '')
      if (newVal.length) this.emailError = !this.$services.common.validator.isEmail(newVal)
    },
    phone(newVal: string) {
      this.$store.commit('rph-contacts-notify/setPhoneError', '')
      if (newVal.length) this.phoneError = this.$services.common.validator.isPhoneNumberFull(newVal)
    } /*
    success(newVal: boolean) {
      if (newVal) {
        this.$store.commit('rph-contacts-notify/setSuccess', false)
      }
    }, */,
  },
  methods: {
    async submit() {
      await this.$store.dispatch('rph-contacts-notify/setContact', {
        name: this.name,
        phone: this.phone,
        text: this.message,
        source: this.$props.source,
        email: this.email,
      })

      if (this.success) {
        this.name = ''
        this.message = ''
        this.phone = ''
        this.email = ''
      }
    },
  },
})
