
import Notification from 'rph/molecules/RPHToast'
import RPHCartToast from 'rph/atoms/RPHCartToast'
export default {
  name: 'Notifications',
  components: {
    Notification,
    RPHCartToast,
  },
  props: {
    theme: {
      type: String,
      default: 'light',
    },
    isCartPage: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      oldLengthList: 0,
    }
  },
  computed: {
    notifications() {
      return this.$store.getters['rph-toast/notifications']
    },
  },
  watch: {
    notifications: {
      deep: true,
      handler() {
        if (this.notifications.length > this.oldLengthList) {
          this.$refs.group.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
          })
          this.oldLengthList = this.notifications.length
        }
      },
    },
  },
}
