import { render, staticRenderFns } from "./index.vue?vue&type=template&id=7851a609&scoped=true"
import script from "./index.vue?vue&type=script&lang=ts"
export * from "./index.vue?vue&type=script&lang=ts"
import style0 from "./index.vue?vue&type=style&index=0&id=7851a609&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7851a609",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Basket: require('/usr/src/app/src/components/atoms/Basket.vue').default,RPHBadge: require('/usr/src/app/src/components/v2/atoms/RPHBadge/index.vue').default,RPHButton: require('/usr/src/app/src/components/v2/atoms/RPHButton/index.vue').default})
