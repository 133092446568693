
import Vue from 'vue'
import { IBanner } from 'types/DTO/banners'

export default Vue.extend({
  name: 'RPHLargeBannerGrid',
  components: {
    RPHLargeBanner: () => import('rph/molecules/RPHLargeBanner/index.vue'),
  },
  computed: {
    banners(): IBanner[] {
      return this.$store.getters['rph-banners/largeBanners']
    },
  },
})
