
import Vue from 'vue'
import { IMenuObject } from '~/types/DTO/menu'
export default Vue.extend({
  name: 'RPHHeaderNav',
  props: {
    /**
     * @values horizontal, vertical
     */
    type: {
      type: String,
      default: 'horizontal',
    },
  },
  data() {
    return {
      links: [
        {
          id: 0,
          title: 'Полезное о здоровье',
          path: '/news',
        },
        {
          id: 1,
          title: 'Доставка и оплата',
          path: '/content/oplata-i-dostavka',
        },
        {
          id: 2,
          title: 'Как сделать заказ',
          path: '#',
        },
        {
          id: 3,
          title: 'Частые вопросы',
          path: '/faq',
        },
        {
          id: 4,
          title: 'Новости',
          path: '/#news',
        },
        {
          id: 5,
          title: 'Отзывы',
          path: '/reviews',
        },
        {
          id: 6,
          title: 'Контакты',
          path: '/contacts',
        },
        {
          id: 7,
          title: 'Публичная оферта',
          path: '/content/publichnaya-oferta',
        },
      ],
    }
  },
  computed: {
    menu(): IMenuObject[] {
      return this.$store.getters['rph-menu/menu']
    },
  },
})
