
import Vue from 'vue'
import RPHPharmacyReport from 'rph/molecules/RPHModals/RPHPharmacyReport/index.vue'

export default Vue.extend({
  name: 'RPHModalContainer',
  components: {
    RPHPharmacyReport,
    RPHModalWindow: () => import('rph/molecules/RPHModals/RPHModalWindow/index.vue'),
    RPHFindAddress: () => import('rph/molecules/RPHModals/RPHFindAddress/index.vue'),
    RPHLogoutConfirm: () => import('rph/molecules/RPHModals/RPHLogoutConfirm/index.vue'),
  },
  computed: {
    isCheckout(): boolean {
      return this.$store.getters['rph-modals/checkoutShow']
    },
    isPharmacy(): boolean {
      return this.$store.getters['rph-modals/pharmacyReportShow']
    },
    isLogout(): boolean {
      return this.$store.getters['rph-modals/logoutConfirm']
    },
  },
})
